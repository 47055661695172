import { IconChevronDown } from "@tabler/icons-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Card from "./Card";
import CheckBox from "./CheckBox";
import StudyIndicator from "./StudyIndicator";

const formatter = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Los_Angeles",
  day: "numeric",
  month: "short",
  year: "numeric",
});

/**
 * @typedef {{
 *   participantId: string | number;
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 *   preTestActivated: boolean;
 *   trainingsActivated: boolean;
 *   postTestActivated: boolean;
 *   postDrivingTestActivated: boolean;
 *   dateJoined: string;
 *   role: string;
 * }} UserCardInfo
 *
 * @param {{
 *   user: UserCardInfo;
 *   selected: boolean;
 *   onToggleSelected: function;
 *   hideOnDeselect: boolean;
 * }} props
 */
const UserListCard = (props) => {
  const { user, selected, onToggleSelected, hideOnDeselect } = props;

  /**
   * @type {UseState<{
   *   participantId: string | number;
   *   fullName: string;
   *   email: string;
   *   dateJoined: string;
   *   preTest: "Activated" | "Not Activated";
   *   trainings: "Activated" | "Not Activated";
   *   postTest: "Activated" | "Not Activated";
   *   progress: number;
   *   letter: string;
   * }>}
   */
  const [parsedUser, setParsedUser] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const hidden = hideOnDeselect && !selected;

  useEffect(() => {
    setExpanded(false);
    setParsedUser(null);
    if (!user) return;

    const {
      participantId,
      firstName,
      lastName,
      email,
      dateJoined,
      preTestActivated,
      trainingsActivated,
      postTestActivated,
      postDrivingTestActivated,
      role,
    } = user;

    const progress = Math.round(Math.random() * 20) * 5;

    const timestamp = Date.parse(dateJoined);
    if (isNaN(timestamp)) return;

    setParsedUser({
      participantId,
      fullName: `${firstName} ${lastName}`,
      dateJoined: formatter.format(timestamp),
      email,
      preTest: preTestActivated ? "Activated" : "Not Activated",
      trainings: trainingsActivated ? "Activated" : "Not Activated",
      postTest: postTestActivated ? "Activated" : "Not Activated",
      postDrivingTest: postDrivingTestActivated ? "Activated" : "Not Activated",
      progress: isNaN(progress) ? "N/A" : `${progress}%`,
      role,
    });
  }, [user]);

  useEffect(() => {
    if (!selected) setExpanded(false);
  }, [selected]);

  return (
    <Card
      className={clsx(
        "user-list-card",
        hidden && "hidden",
        !parsedUser && "loading",
        !hidden && parsedUser && expanded && "expanded",
      )}
    >
      <div className="expand-toggle" onClick={() => setExpanded(!expanded)} />
      <div
        className="user-data-header"
        inert={hideOnDeselect && !selected ? "" : undefined}
      >
        <CheckBox
          className="select-check"
          state={[selected, () => onToggleSelected(user, selected)]}
          variant="dark"
        />
        <span className="user-name">{parsedUser?.fullName}</span>
        <span className="user-pid">{parsedUser?.participantId}</span>
        <span className="user-study">
          <StudyIndicator role={parsedUser?.role} />
        </span>
        <span className="user-progress">{parsedUser?.progress}</span>
        <IconChevronDown
          className={clsx("expand-arrow", expanded && "open")}
          size="2rem"
          stroke={2.4}
        />
      </div>
      <div className="expanded-info">
        {expanded && (
          <>
            <InfoRow k="Email" v={parsedUser?.email} />
            <InfoRow k="Joined on" v={parsedUser?.dateJoined} />
            <InfoRow k="Last Activity" v="M1 S2" />
            <InfoRow
              k="Training"
              v={parsedUser?.trainings}
              className={
                parsedUser?.trainings === "Activated" ? "green" : "red"
              }
            />

            <InfoRow
              k="Quiz 1"
              v={parsedUser?.preTest}
              className={parsedUser?.preTest === "Activated" ? "green" : "red"}
            />
            <InfoRow
              k="Quiz 2"
              v={parsedUser?.postTest}
              className={parsedUser?.postTest === "Activated" ? "green" : "red"}
            />
            <InfoRow
              k="Quiz 3"
              v={parsedUser?.postDrivingTest}
              className={
                parsedUser?.postDrivingTest === "Activated" ? "green" : "red"
              }
            />
          </>
        )}
      </div>
    </Card>
  );
};

/**
 * @param {{
 *   someSelected: boolean;
 *   allSelected: boolean;
 *   onSelectAll: Function;
 *   onDeselectAll: Function;
 *   selectDisabled: boolean;
 * }} props
 */
export const UserListHeader = (props) => {
  const {
    someSelected,
    allSelected,
    onSelectAll,
    onDeselectAll,
    selectDisabled,
  } = props;

  const [shiftDown, setShiftDown] = useState(false);
  const [selectHovered, setSelectHovered] = useState(false);

  window.onkeydown = (event) => setShiftDown(event.shiftKey);
  window.onkeyup = (event) => setShiftDown(event.shiftKey);

  return (
    <Card
      className={clsx("user-list-header")}
      bgColor="var(--list-header-color)"
    >
      <div className="user-data-header">
        <CheckBox
          className="select-check"
          state={[someSelected, someSelected ? onDeselectAll : onSelectAll]}
          icon={allSelected ? "check" : shiftDown ? "plus" : "minus"}
          variant="dark"
          disabled={selectDisabled}
        />
        <span className="user-name">Full Name</span>
        <span className="user-pid">Participant ID</span>
        <span className="user-study">Study</span>
        <span className="user-progress">Progress</span>
        <IconChevronDown
          className="expand-arrow"
          size="2rem"
          visibility="hidden"
        />
      </div>
    </Card>
  );
};

/** @param {{ k: string; v: string; className: string }} */
const InfoRow = ({ k, v, className }) => (
  <div className={clsx("info-row", className)}>
    <span className="info-key">{k}:</span>
    <span className="info-value">{`${v}`}</span>
  </div>
);

export default UserListCard;

export const selectActions = /** @type {const} */ ({
  FETCH: "FETCH",
  SELECT: "SELECT",
  DESELECT: "DESELECT",
  SELECT_ALL: "SELECT_ALL",
  DESELECT_ALL: "DESELECT_ALL",
  UPDATE_LIST: "UPDATE_LIST",
  JSON: "JSON",
  JSON_ALL: "JSON_ALL",
  JSON_ALL_STARTED: "JSON_ALL_STARTED",
  JSON_ALL_PROGRESS: "JSON_ALL_PROGRESS",
  JSON_ALL_CANCEL: "JSON_ALL_CANCEL",
});

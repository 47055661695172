import { Button, setLoading, stopLoading } from "@src/components/Button";
import Card from "@src/components/Card";
import CheckBox from "@src/components/CheckBox";
import DashContainer from "@src/components/DashContainer";
import Modal from "@src/components/Modal";
import PagerFooter from "@src/components/PagerFooter";
import { ContinuousProgressBar } from "@src/components/ProgressBar";
import RadioButton from "@src/components/RadioButton";
import ScrollList from "@src/components/ScrollList";
import UserListCard, { UserListHeader } from "@src/components/UserListCard";
import { DisplayInfo } from "@src/contexts/Contexts";
import { selectActions } from "@src/workers/constants";
import SelectionWorker from "@src/workers/userListWorker.js?worker";
import {
  IconChevronDown,
  IconDownload,
  IconFilter,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { ServerUrl } from "../../Utils";
import InputField, {
  InputHeader,
  SelectField,
} from "../../components/InputField";
import StudyIndicator from "../../components/StudyIndicator";
import "../../styles/research-search.css";

/** @type {Worker} */
const selectionWorker = new SelectionWorker();

export default function SearchUI() {
  document.body.id = "research-search";
  const navigate = useNavigate();
  const userInfo = useLocation().state;

  const { isHighDPI } = useContext(DisplayInfo);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [userData, setUserData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  // const [modalState, setModalState] = useState({ action: "enablePostTest" });
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [stableSearch, setStableSearch] = useState("");
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    task: null,
    study: new Set(),
    completion: new Set(),
    quiz3Status: undefined,
  });
  const [searchBy, setSearchBy] = useState("name");
  const [numUsers, setNumUsers] = useState(0);
  const [viewSelected, setViewSelected] = useState(false);
  const [hideFilters, setHideFilters] = useState(!isHighDPI);
  const downloadBtnRef = useRef(null);
  const overlayFilters = !isHighDPI;

  const [modalState, setModalState] = useState({
    isOpen: false,
    downloaded: 0,
    totalUsers: 0,
    link: "",
    fileName: "",
    dataSize: "",
  });

  const task = {
    QUIZ_1: "preTest",
    TRAINING: "training",
    QUIZ_2: "postTest",
    QUIZ_3: "postDrivingTest",
  };
  const study = {
    VALIDATION_STUDY: "VALIDATION_STUDY",
    EXPERIMENT: "EXPERIMENT",
    EXPERIMENT_AM: "EXPERIMENT_AM",
    CONTROL: "CONTROL",
  };
  const completion = {
    NOT_STARTED: "notstarted",
    IN_PROGRESS: "inprogress",
    COMPLETED: "completed",
  };
  const quiz3Status = {
    NOT_ACTIVATED: false,
    ACTIVATED: true,
  };

  const searchByOptions = {
    participantId: "Participant ID",
    name: "Name",
    email: "Email",
  };

  useEffect(() => {
    if (userInfo?.role !== "RESEARCHER") {
      return navigate("/unauthorized");
    }
    selectionWorker.onmessage = handleSelectionUpdate;
  }, []);

  useEffect(() => {
    const searchStabilizer = setTimeout(() => {
      setStableSearch(search);
    }, 1000);

    return () => clearTimeout(searchStabilizer);
  }, [search]);

  useEffect(() => {
    if (page === 1) {
      if (!filterOptions.task) return;
      updateUserList();
    } else {
      setPage(1);
    }
  }, [filterOptions, itemsPerPage, stableSearch, !!stableSearch && searchBy]);

  useEffect(() => {
    if (!filterOptions.task) return;
    updateUserList(page);
  }, [page]);

  function batchActivateQuiz3() {
    axios
      .post(
        `${ServerUrl}/users/batch-activate-post-driving-test-user`,
        { userIds: selectedIDs },
        { withCredentials: true },
      )
      .then((res) => {
        console.log({ response: res, message: "Users sucessfully activated" });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  /** @type {Worker["onmessage"]} */
  function handleSelectionUpdate({ data: workerData }) {
    const { type, users = "[]", ids = "[]", ...data } = workerData;

    const userIDs = JSON.parse(ids);

    switch (type) {
      case selectActions.FETCH:
        const userList = JSON.parse(users);
        setUserData(userList);
        setNumUsers(data.totalUsers);
        return;

      case selectActions.UPDATE_LIST:
        setAllSelected(data.allSelected);
        return;

      case selectActions.SELECT:
        break;

      case selectActions.DESELECT:
        if (!userIDs.length) {
          setViewSelected(false);
        }
        setAllSelected(false);
        break;

      case selectActions.SELECT_ALL:
        setAllSelected(true);
        break;

      case selectActions.DESELECT_ALL:
        setViewSelected(false);
        setAllSelected(false);
        break;

      case selectActions.JSON:
        downloadJSON(data.href, data.fileName);
        return;

      case selectActions.JSON_ALL:
        return setModalState((prev) => {
          if (!prev.isOpen) {
            URL.revokeObjectURL(data.href);
          } else {
            enableDownloadLink(data.href, data.fileName);
          }

          return prev;
        });

      case selectActions.JSON_ALL_STARTED:
        setModalState((prev) => {
          if (!prev.isOpen) return prev;
          return { ...prev, totalUsers: data.totalUsers };
        });
        return;

      case selectActions.JSON_ALL_PROGRESS:
        setModalState((prev) => {
          if (!prev.isOpen) return prev;
          return { ...prev, downloaded: data.downloaded };
        });
        return;

      default:
        break;
    }

    setSelectedIDs(userIDs);

    setViewSelected((viewingSelected) => {
      setSelectedUsers((curSelected) => {
        const moreUsers = curSelected.length < userIDs.length;

        if (!viewingSelected || moreUsers) {
          const userList = JSON.parse(users);
          return userList;
        }

        return curSelected;
      });

      return viewingSelected;
    });
  }

  function selectUser(user) {
    selectionWorker.postMessage({
      action: selectActions.SELECT,
      id: user.participantId,
    });
  }

  function deselectUser(user) {
    selectionWorker.postMessage({
      action: selectActions.DESELECT,
      id: user.participantId,
    });
  }

  /** @param {MouseEvent | null} event */
  function selectAll(event) {
    if (event?.shiftKey) return deselectAll();

    if (allSelected) return;
    selectionWorker.postMessage({
      action: selectActions.SELECT_ALL,
    });
  }

  /** @param {MouseEvent | null} event */
  function deselectAll(event) {
    if (event?.shiftKey) return selectAll();

    selectionWorker.postMessage({
      action: selectActions.DESELECT_ALL,
    });
  }

  function requestUserJSON() {
    setLoading(downloadBtnRef.current);

    selectionWorker.postMessage({
      action: selectActions.JSON,
    });
  }

  function requestAllUsersJSON() {
    selectionWorker.postMessage({
      action: selectActions.JSON_ALL,
    });
  }

  function enableDownloadLink(link, fileName) {
    const units = ["B", "KB", "MB", "GB"];
    let unitIdx = 0;

    fetch(link)
      .then((res) => res.blob())
      .then((blob) => {
        let size = blob.size;

        while (size > 100) {
          size /= 1024;
          unitIdx += 1;
        }
        unitIdx = Math.min(unitIdx, 3);

        const dataSize = `${Math.round(100 * size) / 100}${units[unitIdx]}`;

        setModalState((prev) => {
          if (!prev.isOpen) return prev;
          return { ...prev, link, fileName, dataSize };
        });
      });
  }

  function downloadJSON(href, fileName) {
    const link = document.createElement("a");
    link.href = href;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    stopLoading(downloadBtnRef.current);
  }

  function handleCheckbox(user = null, selected) {
    selected ? deselectUser(user) : selectUser(user);
  }

  function updateUserList(page = 1) {
    const url = new URL(`${ServerUrl}/users/search`);
    const options = [
      searchBy,
      filterOptions["task"],
      ...filterOptions["completion"],
      ...filterOptions["study"],
    ];
    options.map((option) => {
      url.searchParams.set(option, true);
    });

    if (filterOptions.quiz3Status !== undefined) {
      url.searchParams.set(
        "postDrivingTestActivated",
        Number(filterOptions.quiz3Status),
      );
    }

    url.searchParams.set("page", page);
    url.searchParams.set("pageSize", itemsPerPage);
    if (stableSearch !== "") {
      url.searchParams.set("search", stableSearch);
    }

    setUserData([]);
    selectionWorker.postMessage({
      action: selectActions.FETCH,
      url: url.href,
    });
  }

  function taskOnChange(task) {
    setFilterOptions({ ...filterOptions, task });
  }

  /**
   * @param {keyof typeof filterOptions} filterSet
   * @param {string} filterValue
   */
  function toggleFilterCheck(filterSet, filterValue) {
    setFilterOptions((curFilters) => {
      if (curFilters[filterSet]?.has?.(filterValue)) {
        curFilters[filterSet]?.delete?.(filterValue);
      } else {
        curFilters[filterSet]?.add?.(filterValue);
      }
      return { ...curFilters };
    });
  }

  function quiz3StatusOnChange(quiz3Status) {
    let status = quiz3Status;
    if (filterOptions.quiz3Status === status) {
      status = undefined;
    }
    setFilterOptions({ ...filterOptions, quiz3Status: status });
  }

  function selectedUserPreviews() {
    const previews = [];

    for (let user of selectedUsers) {
      if (previews.length >= 3) break;
      if (!selectedIDs.includes(user.participantId)) continue;

      previews.push(
        <StudyIndicator
          key={previews.length}
          role={user.role}
          zIndex={3 - previews.length}
        />,
      );
    }

    return previews;
  }

  const filterLayout = (
    <>
      <SelectField
        id="search-by"
        className="filter-search"
        label="Search By"
        value={searchBy}
        options={searchByOptions}
        onChange={(e) => setSearchBy(e.target.value)}
        disabled={!filterOptions.task}
      />

      <div className="filter-group">
        <InputHeader className="filter-header" label="Task" />
        <TaskSelect
          label="Quiz 1"
          task={task.QUIZ_1}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
        <TaskSelect
          label="Training"
          task={task.TRAINING}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
        <TaskSelect
          label="Quiz 2"
          task={task.QUIZ_2}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
        <TaskSelect
          label="Quiz 3"
          task={task.QUIZ_3}
          onChange={taskOnChange}
          filterOptions={filterOptions}
        />
      </div>

      <div className="filter-group">
        <InputHeader className="filter-header" label="Study" />
        <Filter
          filterFor="study"
          label="Validation"
          letter="V"
          option={study.VALIDATION_STUDY}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
        <Filter
          filterFor="study"
          label="Experiment HA"
          letter="H"
          option={study.EXPERIMENT}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
        <Filter
          filterFor="study"
          label="Experiment AM"
          letter="A"
          option={study.EXPERIMENT_AM}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
        <Filter
          filterFor="study"
          label="Control"
          letter="C"
          option={study.CONTROL}
          filterOptions={filterOptions}
          onToggle={toggleFilterCheck}
        />
      </div>

      <div className="filter-group">
        <InputHeader className="filter-header" label="Completion" />
        <Filter
          filterFor="completion"
          label="Not Started"
          option={completion.NOT_STARTED}
          onToggle={toggleFilterCheck}
          filterOptions={filterOptions}
        />
        <Filter
          filterFor="completion"
          label="In Progress"
          option={completion.IN_PROGRESS}
          onToggle={toggleFilterCheck}
          filterOptions={filterOptions}
        />
        <Filter
          filterFor="completion"
          label="Complete"
          option={completion.COMPLETED}
          onToggle={toggleFilterCheck}
          filterOptions={filterOptions}
        />
      </div>

      <div>
        <InputHeader className="filter-header" label="Quiz 3 Status" />
        <RadioButton
          label="Activated"
          selected={filterOptions.quiz3Status === quiz3Status.ACTIVATED}
          onChange={() => quiz3StatusOnChange(quiz3Status.ACTIVATED)}
          disabled={!filterOptions.task}
        />
        <RadioButton
          label="Not Activated"
          selected={filterOptions.quiz3Status === quiz3Status.NOT_ACTIVATED}
          onChange={() => quiz3StatusOnChange(quiz3Status.NOT_ACTIVATED)}
          disabled={!filterOptions.task}
        />
      </div>
    </>
  );

  if (userInfo?.role !== "RESEARCHER") return;

  return (
    <DashContainer
      userInfo={userInfo}
      afterTitle={
        <InputField
          className="user-search"
          type="text"
          id="search"
          autoComplete="search"
          value={search}
          placeholder={`Search by ${searchByOptions[searchBy]}`}
          iconLeft={<IconSearch />}
          width="18rem"
          onChange={(e) => setSearch(e.target.value)}
          disabled={!filterOptions.task}
        />
      }
    >
      <Helmet>
        <title>Search | Hazard Perception</title>
      </Helmet>
      <div
        className={clsx(
          "main-search-container",
          hideFilters && "filters-hidden",
          overlayFilters && "overlay-filters",
        )}
      >
        <Card className="filter-container" bgColor="var(--main-bg-color)">
          <div className="title">
            Filters
            <IconX
              className="close-filters"
              size="1.8rem"
              stroke={3}
              onClick={() => setHideFilters(true)}
              style={{ marginLeft: "auto" }}
            />
          </div>
          <OverlayScrollbarsComponent className="filter-list" defer>
            {filterLayout}
          </OverlayScrollbarsComponent>
        </Card>
        <div
          className="search-results"
          onClick={() => {
            if (!overlayFilters || hideFilters) return;
            setHideFilters(true);
          }}
        >
          <div className="title">
            <Button
              className={clsx(
                "open-filters",
                !hideFilters && !overlayFilters && "hidden",
              )}
              variant="secondary"
              label={!overlayFilters && "Filter"}
              icon={<IconFilter stroke={2.7} />}
              onClick={() => setHideFilters(false)}
              tippy={{
                content: "Filter Search",
                disabled: !overlayFilters,
                zIndex: 100,
              }}
              tabIndex={-1}
            />
            <header>Search Users</header>
            {/* <Button
              label="Upload Users"
              variant="secondary"
              onClick={() => navigate("/research/upload")}
            /> */}
            <Button
              className="json-all"
              variant="secondary"
              label="JSON"
              icon={<IconDownload stroke={2.6} />}
              reverse
              onClick={(e) => {
                e.target?.blur?.();

                setModalState({
                  ...modalState,
                  isOpen: true,
                });
              }}
              tippy={{
                content: "Download All Users",
                placement: "left-start",
                appendTo: document.body,
              }}
              tabIndex={-1}
            />
          </div>
          {/* TODO: select all actions (moved) */}
          <UserListHeader
            someSelected={selectedIDs.length > 0}
            allSelected={allSelected}
            onSelectAll={selectAll}
            onDeselectAll={deselectAll}
            selectDisabled={!filterOptions.task}
          />
          {!filterOptions.task ? (
            <div className="task-select-prompt">
              Please select a task in filters to view users
            </div>
          ) : (
            <>
              <Card
                className={clsx(
                  "selected-users",
                  !viewSelected && "compact",
                  !selectedIDs.length && "hidden",
                )}
                bgColor="var(--main-bg-color)"
              >
                <div
                  className="expand-toggle"
                  onClick={() => setViewSelected(!viewSelected)}
                />
                <div className="selection-header">
                  <span className="size">{selectedIDs.length}</span>
                  Selected
                  <span className="preview">
                    {selectedUserPreviews()}
                    {selectedIDs.length > 3 && "+"}
                  </span>
                  <IconChevronDown
                    className={clsx("expand-arrow", viewSelected && "open")}
                    size="2rem"
                    stroke={2.4}
                    style={{ marginLeft: "auto" }}
                  />
                  <div className="select-actions">
                    <Button
                      className="quiz-activate"
                      variant="light"
                      label="Activate Quiz 3"
                      onClick={batchActivateQuiz3}
                      tabIndex={-1}
                    />
                    <Button
                      className="json-download"
                      variant="light"
                      icon={<IconDownload stroke={2.6} />}
                      label="JSON"
                      reverse
                      onClick={requestUserJSON}
                      tabIndex={-1}
                      ref={downloadBtnRef}
                    />
                  </div>
                </div>
                <ScrollList
                  className={clsx(
                    "user-cards selected",
                    !(selectedIDs.length && viewSelected) && "hidden",
                  )}
                  direction="vertical"
                  debounceMS={250}
                  defer
                >
                  {viewSelected &&
                    selectedUsers.map((user, idx) => (
                      <UserListCard
                        key={idx}
                        user={user}
                        selected={selectedIDs.includes(user.participantId)}
                        onToggleSelected={handleCheckbox}
                        hideOnDeselect
                      />
                    ))}
                </ScrollList>
              </Card>
              <ScrollList
                className={clsx(
                  "user-cards",
                  selectedIDs.length && viewSelected && "hidden",
                )}
                direction="vertical"
                debounceMS={250}
                defer
              >
                {userData.map((user, idx) => (
                  <UserListCard
                    key={idx}
                    user={user}
                    selected={selectedIDs.includes(user.participantId)}
                    onToggleSelected={handleCheckbox}
                  />
                ))}
              </ScrollList>
            </>
          )}
        </div>
      </div>
      <PagerFooter
        itemsPerPage={itemsPerPage}
        perPageOptions={[10, 25, 50, 75, 100]}
        onItemsPerPageChange={(e) => setItemsPerPage(e.target.value)}
        selectDisabled={!filterOptions.task}
        page={page}
        numItems={numUsers}
        shownRange={isHighDPI ? 2 : 1}
        onPageChange={(page) => setPage(page)}
      />
      {/* {isActionsOpen && (
        // TODO: not used ??
        <ResearchModal
          openState={[isActionsOpen, setIsActionsOpen]}
          modalState={modalState}
        />
      )} */}
      <Modal
        id="download-all-modal"
        className="download-all-modal"
        title="Download All Users"
        contentLabel="Login Modal"
        isOpen={modalState.isOpen}
        afterOpen={requestAllUsersJSON}
        close={() => {
          selectionWorker.postMessage({
            action: selectActions.JSON_ALL_CANCEL,
          });

          if (modalState.link) URL.revokeObjectURL(modalState.link);

          setModalState({
            ...modalState,
            isOpen: false,
          });
        }}
        afterClose={() => {
          setModalState({
            isOpen: false,
            downloaded: 0,
            totalUsers: 0,
            link: "",
            fileName: "",
            dataSize: "",
          });
        }}
        focusOnOpen
      >
        <p className="download-progress">
          <span>
            {modalState.downloaded / modalState.totalUsers == 1
              ? modalState.link
                ? "Done!"
                : "Aggregating..."
              : "Fetching All Users..."}
          </span>

          {modalState.totalUsers > 0 && (
            <span>
              {modalState.downloaded} / {modalState.totalUsers} Users
            </span>
          )}
        </p>

        <ContinuousProgressBar
          className="download-progress-bar"
          initAnimate
          percentage={(100 * modalState.downloaded) / modalState.totalUsers}
        />

        <Button
          variant={modalState.link ? "light" : "secondary"}
          label={`Download JSON (${modalState.dataSize})`}
          disabled={!modalState.link}
          loading={!modalState.link}
          onClick={() => {
            const { link, fileName } = modalState;
            downloadJSON(link, fileName);

            setModalState({
              ...modalState,
              isOpen: false,
            });
          }}
          styles={{ width: "100%" }}
        />
      </Modal>
    </DashContainer>
  );
}

/**
 * @param {{
 *   label: string;
 *   task: any;
 *   onChange: function;
 *   filterOptions: {};
 * }} props
 */
const TaskSelect = (props) => {
  const { filterOptions, label, task, onChange } = props;

  return (
    <RadioButton
      label={label}
      selected={filterOptions?.task === task}
      onChange={() => onChange?.(task)}
    />
  );
};

/**
 * @param {{
 *   label: string;
 *   letter: string;
 *   option: any;
 *   filterFor: string;
 *   onToggle: function;
 *   filterOptions: {};
 * }} props
 */
const Filter = (props) => {
  const { filterOptions, filterFor, label, letter, option, onToggle } = props;

  return (
    <CheckBox
      label={label}
      state={[
        filterOptions?.[filterFor]?.has(option),
        () => onToggle?.(filterFor, option),
      ]}
      after={letter && <StudyIndicator letter={letter} />}
      disabled={!filterOptions?.task}
    />
  );
};
